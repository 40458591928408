import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    toggleAddPlayer: false,
}

const playerSlice = createSlice({
    name: 'player',
    initialState,
    reducers: {
        setToggleAddPlayer: (state, action) => {
            state.toggleAddPlayer = action.payload
        },
    }
})

export const {
    setToggleAddPlayer
} = playerSlice.actions

export const selectToggleAddPlayer = state => state.player.toggleAddPlayer

export default playerSlice.reducer