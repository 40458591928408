import React, { useEffect, useState } from 'react'
import './PlayerInfo.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectData } from '../../AppSlice'
import { selectAppStyle, selectButtonStyle } from '../../reducers/StyleSlice'
import { useSearchParams } from 'react-router-dom'

const PlayerInfo = ({
  handleNavigate,
  handleAddPlayer,
  minified,
  setToggleAddPlayer,
}) => {

  const [playerName, setPlayerName] = useState('')
  const [isHost, setIsHost] = useState(false)

  const data = useSelector(selectData)
  const appStyle = useSelector(selectAppStyle)
  const buttonStyle = useSelector(selectButtonStyle)

  const dispatch = useDispatch()

  useEffect(() => {
    if (data && data.is_active === false) {
      handleNavigate(`/end-game?room_id=${data.room_id}`)
    }
  },[data, handleNavigate])

  const [searchParams] = useSearchParams()
  
  useEffect(() => {
    const isHostParam = searchParams.get('is_host')
    if (isHostParam) {
      setIsHost(true)
    }
  },[searchParams])

  return (
    <div className='player-info standard-div'>
      {!minified && data ? (
        <h2>Room ID: {data.room_id}</h2>
      ) : ''}
      <form
        onSubmit={(e) => {
          if (playerName !== '') {
            e.preventDefault()
            handleAddPlayer({
              player_name: playerName,
              is_host: isHost
            })
            dispatch(setToggleAddPlayer(false))
            setPlayerName('')
          }
        }}
      >
        {!minified ? (
          <label htmlFor='player-info'>Player Info:</label>
        ) : ''}
        <input 
          type='text'
          name='player-info'
          id='player-info'
          placeholder='Enter Name'
          value={playerName}
          onChange={(e) => {
            setPlayerName(e.target.value)
          }}
          style={appStyle}
          required
        />

        <input type='submit' value='Go!' style={buttonStyle}/>
      </form>
    </div>
  )
}

export default PlayerInfo