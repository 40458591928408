import React from 'react'
import './Footer.css'
import { useSelector } from 'react-redux'
import { selectAppStyle, selectTheme } from '../../reducers/StyleSlice'
import { BiSun, BiSolidMoon } from 'react-icons/bi'

const Footer = ({
  handleChangeTheme,
}) => {

  const theme = useSelector(selectTheme)
  const appStyle = useSelector(selectAppStyle)

  return (
    <footer>
      {theme === 'light' ? (
        <BiSun 
          onClick={handleChangeTheme}
          style={appStyle}
          size='20'
        />
      ) : (
        <BiSolidMoon 
          onClick={handleChangeTheme}
          style={appStyle}
          size='20'
        />
      )}
    </footer>
  )
}

export default Footer