import React from 'react'
import './PointsTable.css'
import { useSelector } from 'react-redux'
import { selectData } from '../../AppSlice'
import { selectAppStyle } from '../../reducers/StyleSlice'
import { useLocation, useSearchParams } from 'react-router-dom'

const PointsTable = ({
    setModalIsOpen,
    setCurrentPlayer
}) => {

    const location = useLocation();

    const appStyle = useSelector(selectAppStyle)

    const data = useSelector(selectData)

    const [searchParams] = useSearchParams()
    const isHost = searchParams.get('is_host')
    const playerId = searchParams.get('player_id')
    
    return (
        <div className='points-table '>
            {data ? (
                <div className='table-container'>
                        {data.players ? (
                            data.players.map(player => {
                                return (
                                    <div className='player-points-column'
                                        key={player.id}
                                    >
                                        <div className='player-points-header'>
                                            <h6 key={player.id}>{player.name}</h6>
                                        </div>
                                        <div key ={player.id} className='player-points-container'>
                                        {data.rounds.map(round => {
                                            return (
                                                round.round.map(roundPlayer => {
                                                    if (roundPlayer.id === player.id) {
                                                        return (
                                                                <p key={roundPlayer.id}>{roundPlayer.points}</p>
                                                                )
                                                            } else {return ''}
                                                        })
                                                        )
                                                    })}
                                        </div>
                                        {isHost === 'true' && location.pathname !== '/end-game'
                                        ? (
                                            <div className='player-points-footer'>
                                                <p
                                                    onClick={() => {
                                                        setCurrentPlayer(player)
                                                        setModalIsOpen({
                                                            open: true,
                                                            method: 'add'
                                                        })
                                                    }}
                                                    style={{
                                                        border: `1px solid ${appStyle.color}`
                                                    }}
                                                >+</p>
                                                <p
                                                    onClick={() => {
                                                        setCurrentPlayer(player)
                                                        setModalIsOpen({
                                                            open: true,
                                                            method: 'subtract'
                                                        })
                                                    }}
                                                    style={{
                                                        border: `1px solid ${appStyle.color}`
                                                    }}
                                                >-</p>
                                            </div>
                                        ) : player.id === playerId && location.pathname !== '/end-game' ? (
                                            <div className='player-points-footer'>
                                                <p
                                                    onClick={() => {
                                                        setCurrentPlayer(player)
                                                        setModalIsOpen({
                                                            open: true,
                                                            method: 'add'
                                                        })
                                                    }}
                                                    style={{
                                                        border: `1px solid ${appStyle.color}`
                                                    }}
                                                >+</p>
                                                <p
                                                    onClick={() => {
                                                        setCurrentPlayer(player)
                                                        setModalIsOpen({
                                                            open: true,
                                                            method: 'subtract'
                                                        })
                                                    }}
                                                    style={{
                                                        border: `1px solid ${appStyle.color}`
                                                    }}
                                                >-</p>
                                            </div>
                                        ) : (<div className='player-points-footer'><p></p></div>)}
                                    </div>
                                )
                            })
                        ) : ''}
                </div>
            ) : ''}
            {/* <Modal
                isOpen={modalIsOpen.open}
                style={{content: {...appStyle, display: 'flex', justifyContent: 'center', height: 'fit-content'}}}
            >
                {modalIsOpen.method === 'add' ? (
                    <AddPoints 
                        player={currentPlayer}
                        setModalIsOpen={setModalIsOpen}
                        handleAddPoints={handleAddPoints}
                    />
                    ) : (
                        <SubtractPoints 
                            player={currentPlayer}
                            setModalIsOpen={setModalIsOpen}
                            handleAddPoints={handleAddPoints}
                        />
                    )
                }
            </Modal> */}
        </div>
    )
}

export default PointsTable