import { createSlice } from "@reduxjs/toolkit"


// players: newTempData.players,
// rounds: [],
// room_id: newRoomId,
// winner: '',
// is_active: true,
// current_round: 1,
// new_game: {start: false, room_id: newRoomId}

const initialState = {
    data: {
        players: [
            {
                points: 0,
                id: 'stock-id1',
                name: 'Loading...'
            },
            {
                points: 0,
                id: 'stock-id2',
                name: 'Loading...'
            },
            {
                points: 0,
                id: 'stock-id3',
                name: 'Loading...'
            },
        ],
        rounds: [
            {
                round: [
                    {
                        points: 0,
                        id: 'stock-id1',
                        name: 'Loading...'
                    },
                    {
                        points: 0,
                        id: 'stock-id2',
                        name: 'Loading...'
                    },
                    {
                        points: 0,
                        id: 'stock-id3',
                        name: 'Loading...'
                    },
                ]
            },
            {
                round: [
                    {
                        points: 0,
                        id: 'stock-id1',
                        name: 'Loading...'
                    },
                    {
                        points: 0,
                        id: 'stock-id2',
                        name: 'Loading...'
                    },
                    {
                        points: 0,
                        id: 'stock-id3',
                        name: 'Loading...'
                    },
                ]
            },
        ],
        roomId: 0,
        winner: '',
        is_active: true,
        current_round: 1,
        new_game: {start: false, room_id: 0},
    },
    currentRound: 1,
}

const appSlice = createSlice({
    initialState,
    name: 'app',
    reducers: {
        setData: (state,action) => {
            state.data = action.payload
        },
        setCurrentRound: (state, action) => {
            state.currentRound = action.payload
        },
        incrementCurrentRound: (state) => {
            state.currentRound += 1
        },
    }
})

export const {
    setData,
    setCurrentRound,
    incrementCurrentRound,
} = appSlice.actions

export const selectData = state => state.app.data
export const selectCurrentRound = state => state.app.currentRound

export default appSlice.reducer