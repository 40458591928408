import React from 'react'
import "./Header.css"
import { useSearchParams } from 'react-router-dom'

const Header = ({
  handleNavigate,
}) => {

  const [searchParams] = useSearchParams()

  const roomId = searchParams.get('room_id')

  return (
    <header onClick={() => handleNavigate('/')}>
      <h1>Two Thousand!</h1>
      {roomId ? <p>Room ID: {roomId}</p> : ''}
    </header>
  )
}

export default Header