import React, { useState } from 'react'
import './JoinRoom.css'
import { useSelector } from 'react-redux'
import { selectAppStyle, selectButtonStyle } from '../../reducers/StyleSlice'

const JoinRoom = ({
    handleJoinRoom,
}) => {

    const [roomCode, setRoomCode] = useState('')
    const appStyle = useSelector(selectAppStyle)
    const buttonStyle = useSelector(selectButtonStyle)

  return (
    <div className='join-room'>
        <form
            onSubmit={(e) => {
                e.preventDefault()
                handleJoinRoom(roomCode.toUpperCase())
            }}
        >
            <label htmlFor='room-code'>Room Code:</label>
            <input 
                type='text' 
                name='room-code' 
                id='room-code' 
                value={roomCode} 
                onChange={(e) => setRoomCode(e.target.value)}
                style={appStyle}
            />

            <input 
                type='submit' 
                value='Go!' 
                style={buttonStyle}
            />
        </form>
    </div>
  )
}

export default JoinRoom