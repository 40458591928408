import React, { useEffect, useState } from 'react'
import './EndGame.css'
import { useSelector } from 'react-redux'
import { selectData } from '../../AppSlice'
import Leaderboard from '../Leaderboard/Leaderboard'
import { useSearchParams } from 'react-router-dom'
import PointsTable from '../PointsTable/PointsTable'

const EndGame = ({
    handleNavigate,
    handleNewGameWithSamePlayers,
    handleReset,
    setModalIsOpen,
    setCurrentPlayer,
}) => {

    const data = useSelector(selectData)
    const [tempArr, setTempArr] = useState([])
    const [newGameCheck, setNewGameCheck] = useState(false)

    const [searchParams] = useSearchParams()
    const isHost = searchParams.get('is_host')
    const playerId = searchParams.get('player_id')

    useEffect(() => {
        if (data) {
            if (data.players && data.players.length > 0) {
                const tempData = JSON.parse(JSON.stringify(data))
                const arr = data.players.slice().sort((a,b) => b.points - a.points)
                tempData.players = arr
                setTempArr(tempData)
            }
        }
    },[data, setTempArr])

    useEffect(() => {
        if (data && data.is_active === true) {
            handleNavigate(`/lobby?room_id=${data.room_id}&player_id=${playerId}&is_host=${isHost}`)
        }
    },[data, handleNavigate, playerId, isHost])

    useEffect(() => {
        console.log(data)
        if (data && data.new_game.start === true) {
            if (data.new_game.room_id === 1111) {
                handleNavigate('/reset')
            } else {
                handleNavigate(`/lobby?room_id=${data.new_game.room_id}&player_id=${playerId}&is_host=${isHost}`)
            }
        }
    },[data, handleNavigate, playerId, isHost])

  return (
    <div className='end-game'>
        {data && data.winner && data.winner.name !== '' ? (
            <div className='winner-box standard-div'>
            <h1>Winner: {tempArr && tempArr.players ? tempArr.players[0].name : ''}</h1>
            </div>
        ) : ''}
        <PointsTable 
            setModalIsOpen={setModalIsOpen}
            setCurrentPlayer={setCurrentPlayer}
        />
        <Leaderboard />
        {isHost === 'true' ? (
            <button
                onClick={() => {
                    setNewGameCheck(!newGameCheck)
                }}
            >New Game</button>
        ) : ''}
        {newGameCheck ? (
            <div className='new-game-check'>
                <button onClick={() => handleNewGameWithSamePlayers()}>Play again, same players</button>
                <button onClick={() => handleReset()}>Start from scratch</button>
            </div>
        ) : ''}
    </div>
  )
}

export default EndGame