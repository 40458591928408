import React, { useEffect, useState } from 'react'
import './Leaderboard.css'
import { useSelector } from 'react-redux'
import { selectData } from '../../AppSlice'

import { GiVikingChurch, GiVikingHelmet, GiVikingLonghouse, GiVikingShield } from 'react-icons/gi'

const Leaderboard = () => {

    const data = useSelector(selectData)
    const [tempArr, setTempArr] = useState([])

    useEffect(() => {
        if (data) {
            if (data.players && data.players.length > 0) {
                const tempData = JSON.parse(JSON.stringify(data))
                const arr = data.players.slice().sort((a,b) => b.points - a.points)
                tempData.players = arr
                setTempArr(tempData)
            }
        }
    },[data, setTempArr])

    return (
        <div style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
        }}>
            {tempArr ? (
                <div className='leaderboard standard-div'>
                  <h2>Leaderboard</h2>
                  <ul className='standard-div'>
                    {tempArr.players ? (
                      tempArr.players.map((player, index) => {
                        return (
                          <li 
                            key={player.id}
                            className={player.points >= 2000 ? 'winner' : ''}
                          >
                            <div>
                              {index === 0 
                                ? (
                                  <GiVikingChurch 
                                    style={{color: 'gold'}}
                                  />
                                )
                                : index === 1
                                ?  (
                                  <GiVikingLonghouse 
                                    style={{color: 'silver'}}
                                  />
                                )
                                : index === 2
                                ? (
                                  <GiVikingHelmet 
                                    style={{color: 'coral'}}
                                  />
                                )
                                : <GiVikingShield 
                                    style={{color: 'white'}}
                                  />
                              }
                              <h4>{player.name}</h4>
                            </div>
                            <h4>{player.points}</h4>
                          </li>
                        )
                      })
                      ) : ''}
                  </ul>
                </div>
            ) : ''}
        </div>
    )
}

export default Leaderboard