import { configureStore } from '@reduxjs/toolkit';
import appReduder from '../AppSlice.js'
import styleReducer from '../reducers/StyleSlice.js'
import playerReducer from '../reducers/PlayerSlice.js'

export const store = configureStore({
  reducer: {
    app: appReduder,
    style: styleReducer,
    player: playerReducer,
  },
});
