import React, { useEffect } from 'react'

const Reset = ({
    handleNavigate,
}) => {

    useEffect(() => {
        window.location.reload()
        handleNavigate('/')
    },[handleNavigate])

  return (
    <div>Reset</div>
  )
}

export default Reset