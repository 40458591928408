import React from 'react'
import "./LandingPage.css"
import { useSelector } from 'react-redux'
import { selectButtonStyle } from '../../reducers/StyleSlice'

const LandingPage = ({
    handleNavigate,
    handleCreateNewRoom,
}) => {
  
  const buttonStyle = useSelector(selectButtonStyle)

  return (
    <div className='landing-page standard-div'>
        <button 
          style={buttonStyle}
        onClick={() => {
            handleCreateNewRoom()
            
        }}>Host Room</button>
        <button 
          style={buttonStyle}
        onClick={() => handleNavigate('/join-room?is_host=false')}>Join Room</button>
    </div>
  )
}

export default LandingPage