import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    theme: 'light',
    appStyle: {},
    buttonStyle: {},
}

const styleSlice = createSlice({
    name: 'style',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload
        },
        setAppStyle: (state, action) => {
            state.appStyle = action.payload
        },
        setButtonStyle: (state, action) => {
            state.buttonStyle = action.payload
        }
    }
})

export const {
    setTheme,
    setAppStyle,
    setButtonStyle,
} = styleSlice.actions

export const selectTheme = state => state.style.theme
export const selectAppStyle = state => state.style.appStyle
export const selectButtonStyle = state => state.style.buttonStyle

export default styleSlice.reducer