import React, { useEffect, useState } from 'react'
import './Lobby.css'
import { useDispatch, useSelector } from 'react-redux'
import { selectData } from '../../AppSlice'
import { useSearchParams } from 'react-router-dom'
import { selectButtonStyle } from '../../reducers/StyleSlice'
import Leaderboard from '../Leaderboard/Leaderboard'
import PointsTable from '../PointsTable/PointsTable'
import AddPoints from '../AddPoints/AddPoints'
import SubtractPoints from '../SubtractPoints/SubtractPoints'
import PlayerInfo from '../PlayerInfo/PlayerInfo'
import { selectToggleAddPlayer, setToggleAddPlayer } from '../../reducers/PlayerSlice'

const Lobby = ({
  handleNavigate,
  handleAddPoints,
  handleEndGame,
  handleAddPlayer,
}) => {

  
  const [modalIsOpen, setModalIsOpen] = useState({
    open: false,
    method: ''
  })
  const [currentPlayer, setCurrentPlayer] = useState({
    name: "",
    id: "",
    is_host: false,
    points: 0,
  })
  const [toggleEndGameCheck, setToggleEndGameCheck] = useState(false)
  
  const data = useSelector(selectData)
  const buttonStyle = useSelector(selectButtonStyle)
  const toggleAddPlayer = useSelector(selectToggleAddPlayer)
  
  const dispatch = useDispatch()
  
  const [searchParams] = useSearchParams()
  const playerId = searchParams.get('player_id')
  const isHost = searchParams.get('is_host')

  useEffect(() => {
    // console.log(data)
    if (data && data.is_active === false) {
      // console.log(data)
      handleNavigate(`/end-game?room_id=${data.room_id}&player_id=${playerId}&is_host=${isHost}`)
    } 
  },[data, handleNavigate, isHost, playerId])



  useEffect(() => {
    const playerId = searchParams.get('player_id')

    if (data && data.players) {
      data.players.forEach(player => {
        if (player.id === playerId) {
          setCurrentPlayer(player)
        }
      })
    }
  },[data, searchParams])

  const handlePressEndGameButton = () => {
    handleEndGame()
    handleNavigate(`/end-game?room_id=${data.room_id}&player_id=${playerId}&is_host=${isHost}`)
  }


  return (
    <main className='standard-div'>
      
        <Leaderboard />
        <PointsTable 
          handleAddPoints={handleAddPoints}
          modalIsOpen={modalIsOpen}
          setModalIsOpen={setModalIsOpen}
          setCurrentPlayer={setCurrentPlayer}
        />
        {isHost === 'true' ? (
          <div className='host-container-div'>
    
            {modalIsOpen.method === 'add' ? (
              <AddPoints 
                player={currentPlayer}
                setModalIsOpen={setModalIsOpen}
                handleAddPoints={handleAddPoints}
              />
              ) : modalIsOpen.method === 'subtract' ? (
                <SubtractPoints
                player={currentPlayer}
                setModalIsOpen={setModalIsOpen}
                handleAddPoints={handleAddPoints}
                />
                ) : ''}
                <button
                  style={buttonStyle}
                  onClick={() => {
                    dispatch(setToggleAddPlayer(!toggleAddPlayer))
                  }}
                >
                  Add Player
                </button>
                {toggleAddPlayer ? (
                  <div className='add-player-container'>
                    <PlayerInfo 
                      minified={true}
                      handleAddPlayer={handleAddPlayer}
                      handleNavigate={handleNavigate}
                      setToggleAddPlayer={setToggleAddPlayer}
                    />
                  </div>
    
                ) : ''}
            <button
              style={buttonStyle}
              onClick={(() => {
                setToggleEndGameCheck(!toggleEndGameCheck)
              })}
            >End Game</button>
            {toggleEndGameCheck ? (
              <div className='end-game-check'>
                <p>Are you sure?</p>
                <div>
                  <button onClick={() => handlePressEndGameButton()}>Yes</button>
                  <button onClick={() => setToggleEndGameCheck(!toggleEndGameCheck)}>No</button>
                </div>
              </div>
            ) : ''}
          </div>
        ) : (
          <div className='player-container-div'>
            {modalIsOpen.method === 'add' ? (
              <AddPoints 
                player={currentPlayer}
                setModalIsOpen={setModalIsOpen}
                handleAddPoints={handleAddPoints}
              />
              ) : modalIsOpen.method === 'subtract' ? (
                <SubtractPoints
                  player={currentPlayer}
                  setModalIsOpen={setModalIsOpen}
                  handleAddPoints={handleAddPoints}
                />
                ) : ''}
          </div>
        )}
    </main>
  )
}

export default Lobby