import React, { useState } from 'react'
import './SubtractPoints.css'
import { useSelector } from 'react-redux'
import { selectAppStyle, selectButtonStyle } from '../../reducers/StyleSlice'
import { selectData } from '../../AppSlice'

const SubtractPoints = ({
    player,
    handleAddPoints,
    setModalIsOpen,
}) => {

    

    const [points, setPoints] = useState('')

    const data = useSelector(selectData)
    const appStyle = useSelector(selectAppStyle)
    const buttonStyle = useSelector(selectButtonStyle)

  return (
    <div className='add-points'
        style={appStyle}
    >
        <div className='standard-div host-control' key={player.id}>
            <h4>{player.name}</h4>
            <form
                className='standard-div'
                onSubmit={(e)=> {
                    e.preventDefault()
                    if (points < 0) {
                        handleAddPoints(player.id, points)
                    } else if (points >= 0) {
                        handleAddPoints(player.id, points * -1)
                    }
                    setPoints('')
                    setModalIsOpen(false)
                }}
            >
            {/* <label htmlFor='add-points'>Subtract Points:</label> */}
            <input 
                type='number' 
                id='add-points'
                value={points} 
                onChange={(e) => setPoints(e.target.value)} 
                inputMode='numeric'
                style={appStyle}
                placeholder='Enter Points To Subtract'
            />
            <div className='buttons'>
                <input 
                    type='submit' 
                    value='Subtract' 
                    style={data.is_active === false ? {
                        backgroundColor: 'var(--main-grey2)',
                        color: 'var(--main-black)',
                        textDecoration: 'line-through',
                        display: 'none'
                    } : buttonStyle}
                />
                <button
                    onClick={() => setModalIsOpen(false)}
                >Cancel</button>
            </div>
            </form>
        </div>
    </div>
  )
}

export default SubtractPoints