import React, { useEffect } from 'react';
import './App.css';

import Header from './features/Header/Header'
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import LandingPage from './features/LandingPage/LandingPage';
import PlayerInfo from './features/PlayerInfo/PlayerInfo';
import Footer from './features/Footer/Footer';
import JoinRoom from './features/JoinRoom/JoinRoom';
import { doc, increment, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { db } from './firebaseConfig.js'
import { useDispatch, useSelector } from 'react-redux';
import { 
  selectCurrentRound, 
  setCurrentRound, 
  selectData, 
  setData, 
} from './AppSlice';
import { v4 as uuidv4 } from 'uuid'
import Lobby from './features/Lobby/Lobby';
import { selectAppStyle, selectTheme, setAppStyle, setButtonStyle, setTheme } from './reducers/StyleSlice';
import EndGame from './features/EndGame/EndGame';
import Reset from './features/Reset/Reset';
import ReactModal from 'react-modal';
import { setToggleAddPlayer } from './reducers/PlayerSlice';


const generateId = () => {
  let id = ''

  const min = 65
  const max = 90

  for (let i=0; i<4; i++) {
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    id += String.fromCharCode(randomNumber)
  }
  return id;
}

ReactModal.setAppElement('#root')


function App() {

  const data = useSelector(selectData)
  const theme = useSelector(selectTheme)
  const appStyle = useSelector(selectAppStyle)
  const currentRound = useSelector(selectCurrentRound)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [searchParams] = useSearchParams()

  
  useEffect(() => {
    const fetchData = async () => {
      const roomId = searchParams.get('room_id')
      if (roomId) {
        const unsub = onSnapshot(doc(db, 'rooms', roomId), (doc) => {
          dispatch(setData(doc.data()))
          dispatch(setCurrentRound(doc.data().current_round))
        })
        return unsub
      }
    }

    fetchData()
  },[dispatch, searchParams])

  useEffect(() => {
    const theme = localStorage.getItem('theme')

    if (!theme) {
      localStorage.setItem('theme', 'dark')
      dispatch(setTheme('dark'))
    } else {
      dispatch(setTheme(theme))
    }
  },[dispatch])
  
  useEffect(() => {
    if (theme === 'light') {
      dispatch(setAppStyle({
        backgroundColor: 'var(--main-white)',
        color: 'var(--main-black)',
        borderBottom: '1px solid var(--main-black)'
      }))
      dispatch(setButtonStyle({
        backgroundColor: 'var(--main-black)',
        color: 'var(--main-white)',
        borderBottom: '1px solid var(--main-black)'
      }))
    } else {
      dispatch(setAppStyle({
        backgroundColor: 'var(--main-black)',
        color: 'var(--main-white)',
        borderBottom: '1px solid var(--main-white)'
      }))
      dispatch(setButtonStyle({
        backgroundColor: 'var(--main-white)',
        color: 'var(--main-black)',
        borderBottom: '1px solid var(--main-white)'
      }))
    }
  },[theme, dispatch])


  const handleChangeTheme = () => {
    const theme = localStorage.getItem('theme')

    if (theme) {
      if (theme === 'light') {
        localStorage.setItem('theme', 'dark')
        dispatch(setTheme('dark'))
      } else if (theme === 'dark') {
        localStorage.setItem('theme', 'light')
        dispatch(setTheme('light'))
      } else {
        return
      }
    }
  }


  const handleNavigate = (page) => {
    navigate(`${page}`)
  }

  const handleCreateNewRoom = async () => {
    const roomId = generateId()

    const docRef = doc(db, 'rooms', roomId)

    await setDoc(docRef, {
      players: [],
      rounds: [],
      room_id: roomId,
      winner: '',
      is_active: true,
      current_round: 1,
      new_game: {start: false, room_id: roomId},
    })

    // const tempData = {...data, roomId: roomId}
    // dispatch(setData(tempData))

    handleNavigate(`/player-info?is_host=true&room_id=${roomId}`)
  }

  const handleAddPlayer = async (playerData) => {
    const tempData = JSON.parse(JSON.stringify(data))
    const playerId = uuidv4()

    tempData.players.push({
      name: playerData.player_name,
      is_host: playerData.is_host,
      points: 0,
      id: playerId
    })

    const docRef = doc(db, 'rooms', data.room_id)
    await updateDoc(docRef, tempData)

    handleNavigate(`/lobby?room_id=${data.room_id}&player_id=${playerId}&is_host=${playerData.is_host}`)

  }

  const handleJoinRoom = async (roomId) => {
    handleNavigate(`/player-info?room_id=${roomId}`)
  }

  const handleAddPoints = async (playerId, points) => {

    if (data.is_active && points !== '') {
      const tempData = JSON.parse(JSON.stringify(data))
  
      tempData.players.forEach(player => {
        if (player.id === playerId) {
          player.points += Number(points)

        }
      })

      //set currentIndex for round
      let currentIndex = currentRound - 1
      //check if current round has round array in rounds master array
      if (!tempData.rounds[currentIndex]) {
        //if not, then:
        tempData.players.forEach(player => {
          if (player.id === playerId) {
            //create object with round array consisting of first player to add points this round
            tempData.rounds[currentIndex] = {
              round: [player]
            }
          }
        })
        //if current round has round array in rounds master array:
      } else if (tempData.rounds[currentIndex].round.length < tempData.players.length) {
        // Search players array for player matching playerId
        const playerIndex = tempData.players.findIndex(player => player.id === playerId);
    
        if (playerIndex !== -1) {
            // Player with playerId found in the players array
            const player = tempData.players[playerIndex];
    
            // Search round if player has an entry already
            const roundPlayerIndex = tempData.rounds[currentIndex].round.findIndex(roundPlayer => roundPlayer.id === playerId);
    
            if (roundPlayerIndex !== -1) {
                // Player already has an entry in the round, so update it
                tempData.rounds[currentIndex].round[roundPlayerIndex] = player;
            } else {
                // Player does not have an entry in the round, so push the player to the round array
                tempData.rounds[currentIndex].round.push(player);
            }
        }
    }
      // else if (tempData.rounds[currentIndex].round.length < tempData.players.length) {
      //   //search players array for player matching playerId
      //   tempData.players.forEach(player => {
      //     if (player.id === playerId) {

      //       //search round if player has entry already
      //       tempData.rounds[currentIndex].round.forEach((roundPlayer, index) => {
      //         if (roundPlayer.id === playerId) {
      //           //replace old entry for player with new one
      //           tempData.rounds[currentIndex].round[index] = player
      //         } else {
      //           //player does not already have entry in round, so push player to round array
      //           tempData.rounds[currentIndex].round.push(player)
      //         }
      //       })

      //     }
      //   })
      // } 
      // else {
      //   tempData.players.forEach(player => {
      //     console.log('this is the else statement on App.js:187')
      //     if (player.id === playerId) {
      //       tempData.rounds[currentIndex] = {
      //         round: [player]
      //       }
      //     }
      //   })
      // }
      // console.log(tempData)
      const docRef = doc(db, 'rooms', data.room_id)
      await updateDoc(docRef, tempData)

      //check if current round array has length equal to players array, if so increment current round
      if (tempData.rounds[currentIndex] && tempData.rounds[currentIndex].round.length >= tempData.players.length) {
        const docRef = doc(db, 'rooms', data.room_id)
        await updateDoc(docRef, {
          current_round: increment(1)
        })
      }

  
    }
  }

  const handleEndGame = async () => {
    const tempData = JSON.parse(JSON.stringify(data))

    tempData.is_active = false

    if (data.players && data.players.length > 0) {
      const arr = data.players.slice().sort((a,b) => b.points - a.points)
      tempData.winner = arr[0]
    }

    const docRef = doc(db, 'rooms', data.room_id)
    await updateDoc(docRef, tempData)
  }
  
  const handleNewGameWithSamePlayers = async () => {
    // players: [],
    // rounds: [],
    // room_id: roomId,
    // winner: '',
    // is_active: true,
    // current_round: 1,
    // new_game: {start: false, room_id: roomId}

    const newRoomId = generateId()
    const tempData = JSON.parse(JSON.stringify(data))

    tempData.players.forEach(player => {
      player.points = 0
    })
    tempData.rounds = []
    tempData.winner = ''
    tempData.is_active = true
    tempData.current_round = 1
    tempData.new_game = {
      start: true,
      room_id: newRoomId
    }

    console.log(tempData)
    const newRoomData = {...tempData, 
      room_id: newRoomId,
      new_game: {start: false, room_id: newRoomId}
    }

    //create new room document in db
    const newRoomDocRef = doc(db, 'rooms', newRoomId)
    await setDoc(newRoomDocRef, newRoomData)

    //update old room to trigger switch to new room
    const docRef = doc(db, 'rooms', tempData.room_id)
    await updateDoc(docRef, tempData)

  }

  const handleReset = async () => {
    const tempData = JSON.parse(JSON.stringify(data))

    tempData.new_game = {
      start: true,
      room_id: 1111
    }
    
    const docRef = doc(db, 'rooms', tempData.room_id)
    await updateDoc(docRef, tempData)

  }


  return (
    <div 
      className="App"
      id='app'
      style={appStyle}  
    >
      <Header 
        handleNavigate={handleNavigate}
      />
      <Routes>
        <Route path='/' element={
          <LandingPage 
            handleNavigate={handleNavigate}
            handleCreateNewRoom={handleCreateNewRoom}
          />
        }></Route>
        <Route path='join-room' element={
          <JoinRoom 
            handleNavigate={handleNavigate}
            handleJoinRoom={handleJoinRoom}
          />
        }></Route>
        <Route path='/player-info' element={
          <PlayerInfo 
            handleNavigate={handleNavigate}
            handleAddPlayer={handleAddPlayer}
            minified={false}
            setToggleAddPlayer={setToggleAddPlayer}
          />
        }></Route>
        <Route path='/lobby' element={
          <Lobby 
            handleNavigate={handleNavigate}
            handleAddPoints={handleAddPoints}
            handleEndGame={handleEndGame}
            handleAddPlayer={handleAddPlayer}
          />
        }></Route>
        <Route path='/end-game' element={
          <EndGame 
            handleNavigate={handleNavigate}
            handleNewGameWithSamePlayers={handleNewGameWithSamePlayers}
            handleReset={handleReset}
          />
        }></Route>
        <Route path='/reset' element={
          <Reset 
            handleNavigate={handleNavigate}
          />
        }></Route>
      </Routes>
      <Footer 
        handleChangeTheme={handleChangeTheme}
      />
    </div>
  );
}

export default App;
